import React, { useEffect, useState } from "react";

import { Button, makeStyles } from "@material-ui/core";
import {
  Block,
  MailOutline,
  Phone,
  AccountCircle,
  InfoOutlined,
  Done,
  RecentActors,
  BusinessCenter,
  Business,
} from "@material-ui/icons";
import { BackdropDialog } from "../../common/BackdropDialog/BackdropDialog";
import { UserLabel } from "components/common/Label";
import { getCustomerMonthlyFlightDetails } from "api/user";
import moment from "moment";
interface IUserDetailProps {
  open: boolean;
  handleClose: Function;
  selectedUser: any;
  handleToggleBlock?: Function;
  showChangeDetails?: () => void;
}

const useStyles = makeStyles((theme) => ({
  thStyle: {
    fontWeight: "bold",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  customerDetail: {
    fontSize: 14,
  },
}));
export const UserDetailPopup: React.FC<IUserDetailProps> = (props) => {
  const classes = useStyles();

  const [flightBillingState, setFlightBillingState] = useState<
    Array<{
      month_year: string;
      look_count: number;
      book_count: number;
    }>
  >([]);

  const [showChangeDetails, setShowChangeDetails] = useState(false);

  const getMonthlyFlightDetails = async () => {
    const response = await getCustomerMonthlyFlightDetails({
      userId: props.selectedUser.users_id,
    });

    if (response.data.success) {
      setFlightBillingState(response.data.result);
    }
  };

  useEffect(() => {
    if (props.selectedUser) {
      setFlightBillingState([]);
      getMonthlyFlightDetails();
    }
  }, [props.selectedUser]);

  return (
    <BackdropDialog
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
    >
      {props.selectedUser && (
        <div className="text-left">
          <h5 className=" fw-bolder m-3 mt-4">Customer Details:</h5>

          <UserLabel
            label="Name"
            prefix={<AccountCircle className={classes.customerDetail} />}
            value={
              props.selectedUser.firstName + " " + props.selectedUser.lastName
              // props.selectedUser.customer
              //   ? props.selectedUser.customer.firstName +
              //     props.selectedUser.customer.lastName
              //   : props.selectedUser.company
              //   ? props.selectedUser.company.companyName
              //   : props.selectedUser.staff
              //   ? props.selectedUser.staff.staffName
              //   : ""
            }
          />

          <UserLabel
            label="Email"
            prefix={<MailOutline className={classes.customerDetail} />}
            value={
              props.selectedUser.email
              // props.selectedUser.customer
              //   ? props.selectedUser.customer.email
              //   : props.selectedUser.company
              //   ? props.selectedUser.company.primaryEmail
              //   : props.selectedUser.staff
              //   ? props.selectedUser.staff.email
              //   : ""
            }
          />
          {props.selectedUser?.phoneNumbers?.map((pn) => (
            <UserLabel
              label={`${pn.label} Phone`}
              prefix={<Phone className={classes.customerDetail} />}
              value={
                pn.phoneNumber
                // props.selectedUser.customer
                //   ? props.selectedUser.customer.phone
                //   : props.selectedUser.company
                //   ? props.selectedUser.company.primaryPhone
                //   : props.selectedUser.staff
                //   ? props.selectedUser.staff.mobile
                //   : ""
              }
            />
          ))}
          <UserLabel
            prefix={<InfoOutlined className={classes.customerDetail} />}
            label="Type"
            value={props.selectedUser.userType}
          />
          <UserLabel
            prefix={<Done className={classes.customerDetail} />}
            label="Email Verified"
            value={props.selectedUser.status === "verified" ? "Yes" : "No"}
          />
          <UserLabel
            prefix={<Block className={classes.customerDetail} />}
            label="Blocked"
            value={props.selectedUser.isBlocked ? "Yes" : "No"}
          />
          {props.selectedUser.companyName && (
            <UserLabel
              prefix={<Business className={classes.customerDetail} />}
              label="Company Name"
              value={props.selectedUser.companyName}
            />
          )}
          {props.selectedUser.panNumber && (
            <UserLabel
              prefix={<RecentActors className={classes.customerDetail} />}
              label="Pan Number"
              value={props.selectedUser.panNumber}
            />
          )}
          {props.selectedUser.gstNumber && (
            <UserLabel
              prefix={<BusinessCenter className={classes.customerDetail} />}
              label="GST Number"
              value={props.selectedUser.gstNumber}
            />
          )}

          {props.selectedUser.company && (
            <>
              <hr />
              <h5 className=" fw-bolder m-3 mt-4">Company Details:</h5>

              <UserLabel
                label="Company Name"
                value={props.selectedUser.company.companyName}
                prefix={null}
              />
              <UserLabel
                label="Company Person Name"
                value={
                  props.selectedUser.firstName +
                  " " +
                  props.selectedUser.lastName
                }
                prefix={null}
              />
              <UserLabel
                label="Primary Email"
                value={props.selectedUser.email}
                prefix={null}
              />
              <UserLabel
                label="Primary Phone"
                value={props.selectedUser.phone}
                prefix={null}
              />
              <UserLabel
                label="Registered Office Address:"
                value={`${props.selectedUser.company.address1}, ${props.selectedUser.company.address2}`}
                prefix={null}
              />
              <UserLabel
                label="Secondary Phone:"
                value={props.selectedUser.company.secondaryPhone}
                prefix={null}
              />
            </>
          )}

          {props.selectedUser.billing && (
            <>
              <hr />
              <h5 className="m-3 mt-4 fw-bolder">Billing Details:</h5>
              <UserLabel
                label="Billing Name"
                value={props.selectedUser.billing.billingName}
                prefix={null}
              />
              <UserLabel
                label="GST Number"
                value={props.selectedUser.billing.gstNumber}
                prefix={null}
              />
              <UserLabel
                label="GST State"
                value={props.selectedUser.billing.gstState}
                prefix={null}
              />
              <UserLabel
                label="PAN Number"
                value={props.selectedUser.billing.panNumber}
                prefix={null}
              />
            </>
          )}

          {props.handleToggleBlock && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 text-center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (props.handleToggleBlock) {
                        props.handleToggleBlock(
                          props.selectedUser.users_id,
                          props.selectedUser.isBlocked
                        );
                      }
                    }}
                  >
                    {props.selectedUser.isBlocked ? "Unblock" : "Block"}
                  </Button>
                  {props.showChangeDetails && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (props.showChangeDetails) {
                          props.showChangeDetails();
                        }
                      }}
                    >
                      Change Details
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
          {flightBillingState?.length > 0 && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h5 className="fw-bolder m-3 mt-4">Flight Search Details:</h5>
                </div>
                <>
                  {flightBillingState.map((item, index) => (
                    <div className="col-md-6 col-12" key={index}>
                      <h5>
                        {moment(item.month_year, "YYYY MM").format("MMMM YYYY")}
                      </h5>
                      Total Searches: {item.look_count}
                      <br />
                      Total Bookings: {item.book_count}
                      <br />
                      <hr />
                    </div>
                  ))}
                </>
              </div>
            </div>
          )}
        </div>
      )}
    </BackdropDialog>
  );
};
