/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
} from "@material-ui/core";

import { ChevronRight } from "@material-ui/icons";

import { useSelector } from "react-redux";
import { RouteConstant } from "flight-react/utils/routeConstant";
import { TileMenuBoxes } from "./TileMenuBoxes";

import useFormConstantLabel from "flight-react/hooks/useFormConstantLabel";
import { useAppSelector } from "flight-react/hooks/reduxHooks";

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.primary.main} !important`,
    padding: "0 !important",
    margin: "0 !important",
    "&:hover": {
      color: `${theme.palette.secondary.main} !important`,
      textDecoration: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  btn: {
    border: "none",
  },
  navBtns: {
    fontSize: 10,
    border: `1px solid ${theme.palette.primary.main}`,
    lineHeight: 1.2,
    textTransform: "capitalize",
    background: theme.palette.primary.main,
    margin: "0 1px",
    padding: "6px 10px 0",
    color: "#fff",
    borderRadius: 7,
    display: "flex",
    flexDirection: "column",
  },
}));

export interface ITileMenu {
  title: JSX.Element;
  link: string;
  isRedirecting?: boolean;
  siteModuleId?: number;
  submenus?: Array<ISubmenu>;
  open?: boolean;
  icon: string;
  siteModuleLabel?: string;
}

export interface ISubmenu {
  title: string | React.ReactNode;
  link: string;
  isRedirecting?: boolean;
  menuIndex?: number;
  submenus?: ISubmenu[];
}

export default function StaffMenu() {
  const classes = useStyles();
  const history = useHistory();

  const fdLabel = useFormConstantLabel("fixedDepartureLabel");
  const user = useSelector((state: any) => state.user);

  const siteData = useAppSelector((state: any) => state.siteData);

  let onlyFlightSupplierMenu: Array<ISubmenu> = [];

  if (
    [
      "localhost:3000",
      "testing2.landarrangement.com",
      "new.ticketvicket.com",
    ].includes(window.location.host)
  ) {
    onlyFlightSupplierMenu = [
      {
        title: "Supplier Infozeal Export",
        link: "/staff/infozealExcelDataExport",
      },
      {
        title: "TBO Refund Excel Import",
        link: "/staff/tboRefundExcelImport",
      },
    ];
  }

  const getSettingsSubMenu = () => {
    const settingsSubmenu = [
      {
        title: "Seo Settings",
        link: "/staff/setting/seo-settings",
      },
      {
        title: "Other Settings",
        link: "/staff/setting/other-settings",
      },
    ];
    if (siteData.wlMeta.canShowPermissionMenu) {
      settingsSubmenu.push({
        title: "User Permissions",
        link: "/staff/setting/permission-groups",
      });
    }
    return settingsSubmenu;
  };

  const getLiveFlightsSubMenu = () => {
    const liveFlightSubMenu: ISubmenu[] = [
      {
        link: "/flight/ticketQueue",
        title: "Bookings/Tickets",
      },

      // {
      //   link: RouteConstant.CHANGE_REQUESTS,
      //   title: "Change Requests",
      // },
      // {
      //   link: "/flight/bookedQueue",
      //   title: "Booking Queue"
      // },
      // {
      //   link: RouteConstant.FLIGHT_SUPPLIER_REPORT,
      //   title: "Flight Supplier Report",
      // },
      // {
      //   title: "Markup/Discount",
      //   link: "/staff/flightMarkupDiscount",
      // },
      {
        title: "Flight Settings",
        link: "/staff/flightSettings",
      },
      {
        link: "/flights/ticketQueue",
        title: (
          <>
            Ticket Queue <i className="fas fa-dot-circle blink"> New</i>
          </>
        ),
        isRedirecting: true,
      },
      {
        link: "/flights/changeRequestQueue",
        title: (
          <>
            Change Request Queue <i className="fas fa-dot-circle blink"> New</i>
          </>
        ),
        isRedirecting: true,
      },
      {
        link: "/flights/manage-markup",
        title: (
          <>
            Manage Markup <i className="fas fa-dot-circle blink"> New</i>
          </>
        ),
        isRedirecting: true,
      },
      ...onlyFlightSupplierMenu,
    ];

    // if (siteData.wlMeta.isFlightV2Enabled || true) {
    liveFlightSubMenu.push();
    // }
    return liveFlightSubMenu;
  };

  const staffMenus: Array<ITileMenu> = [
    {
      link: "#",
      title: <>Dashboard</>,
      open: false,
      icon: "fas fa-home",
      submenus: [
        {
          link: "/user/dashboard/",
          title: "Dashboard",
        },
        {
          link: "/staff/customers",
          title: "Customers",
        },
        {
          link: "/staff/link_supplier",
          title: "Suppliers",
        },
        // {
        //   link: "/staff/email_subscribers",
        //   title: "Email Subscribers",
        // },
        // {
        //   link: "/staff/kart",
        //   title: "Kart",
        // },

        // {

        {
          link: "/crm",
          title: "CRM",
          isRedirecting: true,
        },
        {
          link: "/user/dashboard/referral",
          title: "Referrals",
        },
      ],
    },
    {
      link: "#",
      title: <>Flights</>,
      icon: "fas fa-plane",
      submenus: [
        {
          link: "#",
          title: "Live Flights",
          submenus: getLiveFlightsSubMenu(),
          // submenus: [
          //   {
          //     link: "/flight/ticketQueue",
          //     title: "Bookings/Tickets",
          //   },
          //   // {
          //   //   link: RouteConstant.CHANGE_REQUESTS,
          //   //   title: "Change Requests",
          //   // },
          //   // {
          //   //   link: "/flight/bookedQueue",
          //   //   title: "Booking Queue"
          //   // },
          //   // {
          //   //   link: RouteConstant.FLIGHT_SUPPLIER_REPORT,
          //   //   title: "Flight Supplier Report",
          //   // },
          //   {
          //     title: "Markup/Discount",
          //     link: "/staff/flightMarkupDiscount",
          //   },
          //   {
          //     title: "Settings",
          //     link: "/staff/flightSettings",
          //   },
          //   ...onlyFlightSupplierMenu,
          // ],
        },
        // {
        //   link: "#",
        //   // icon: "fas fa-plane-departure",
        //   title: `${fdLabel}`,
        //   submenus: [
        //     // {
        //     //   link: RouteConstant.FIXED_DEPARTURE_QUEUE,
        //     //   title: "Bookings/Tickets",
        //     // },
        //     // {
        //     //   link: "/staff/fixedDepartureInventory",
        //     //   title: "My Inventory",
        //     // },
        //     // {
        //     //   link: "/staff/fixedDepartureBookings",
        //     //   title: "Supplier Bookings",
        //     // },

        //     // {
        //     //   link: RouteConstant.DEPRECATED_FIXED_DEPARTURE_QUEUE,
        //     //   title: "Old Booking Queue",
        //     // },
        //     // {
        //     //   link: "/fixed-departures/add-fixed-departure",
        //     //   title: `Add ${fdLabel}`,
        //     // },
        //     // {
        //     //   link: "/fixed-departures",
        //     //   title: `All ${fdLabel}`,
        //     // },
        //     // {
        //     //   link: "#",
        //     //   title: `Supplier Api Logs`,
        //     //   submenus: [
        //     //     {
        //     //       link: "/staff/supplier-api/AiriqLogs",
        //     //       title: "AiriqLogs",
        //     //     },
        //     //     {
        //     //       link: "/staff/supplier-api/AlgoSpinLogs",
        //     //       title: "AlgoSpinLogs",
        //     //     },
        //     //     {
        //     //       link: "/staff/supplier-api/TFNexusLogs",
        //     //       title: "TFNexusLogs",
        //     //     },
        //     //     {
        //     //       link: "/staff/supplier-api/GoFlySmartLogs",
        //     //       title: "GoFlySmartLogs",
        //     //     },
        //     //     {
        //     //       link: "/staff/supplier-api/FlyBiharLogs",
        //     //       title: "FlyBiharLogs",
        //     //     },
        //     //   ],
        //     // },
        //   ],
        // },
      ],
      open: false,
    },
    {
      link: "#",
      title: <>Transactions</>,
      icon: "fas fa-wallet",
      submenus: [
        {
          link: "#",
          title: "Supplier",
          submenus: [
            {
              link: "/staff/supplierWallets",
              title: "Wallets",
            },
            {
              link: "/staff/myPendingPaymentGatewayEntries",
              title: "Pending PG Transactions",
            },
          ],
        },
        {
          link: "#",
          title: "Customer",
          submenus: [
            {
              link: "/staff/walletReport",
              title: "Wallet Transactions",
            },
            // {
            //   link: "/staff/oldTransactionsExcel",
            //   title: "Old Transactions (Excel Download)",
            // },
            // {
            //   link: `/staff/${STAFF_ROUTES.CONSUMER_WALLET}`,
            //   title: "API User Wallet",
            // },
            {
              link: "/staff/paymentGatewayTransactions",
              title: "Payment Gateway Transactions",
            },
            {
              link: "/staff/supplierProfitLoss",
              title: "Profit Loss Statement",
            },
            {
              link: "/staff/downloadBalances",
              title: "Download Balances",
            },
          ],
        },
        {
          link: "#",
          title: "Verification",
          submenus: [
            {
              link: "/staff/verifyPanCard",
              title: "Verify PAN Card",
            },
          ],
        },
      ],
      open: false,
    },

    {
      link: "#",
      icon: "fas fa-hotel",
      title: <>Packages</>,
      submenus: [
        {
          link: "#",
          title: "DMC",
          submenus: [
            {
              link: "/staff/dmc_master",
              title: "New Kart (DMC)",
            },
            {
              link: "/staff/kart",
              title: "Kart List (DMC)",
            },
            {
              link: "/staff/dmc_job_sheet",
              title: "Job Sheet (DMC)",
            },
            {
              link: "/staff/dmc_rate_management",
              title: "Rate Management (DMC)",
            },
            {
              link: "/staff/dmc-data-management",
              title: "All Data (DMC)",
            },
            {
              link: "/staff/dmc-datewise-itineraries",
              title: "Date Wise Itineraries (DMC)",
            },
          ],
        },

        {
          link: "/staff/tour_package/list_all_packages",
          title: "View All Packages",
        },
        {
          link: "/staff/tour_package/create_or_edit_tour_package",
          title: "Add New Package",
        },
      ],
      open: false,
    },

    {
      link: "#",
      icon: "fas fa-cog",
      title: <>Settings</>,
      submenus: getSettingsSubMenu(),
    },
  ];

  // if (siteData.wlMeta.canShowPermissionMenu) {
  //   staffMenus.forEach((menu) => {
  //     if (menu.siteModuleLabel === "Settings") {
  //       menu.submenus?.push({
  //         title: "User Permissions",
  //         link: "/staff/setting/permission-groups",
  //       });
  //     }
  //   });

  return (
    <>
      {user.userType === "staff" && (
        <>
          <TileMenuBoxes menus={staffMenus} />

          <Dialog
            // onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={false}
          >
            <DialogTitle id="simple-dialog-title">Staff Menu</DialogTitle>
            <List>
              {staffMenus.map((menu, indx) => (
                <React.Fragment key={indx}>
                  <ListItem
                    button
                    onClick={() => {
                      if (!window.location.href.includes(menu.link)) {
                        history.push(menu.link);
                      }
                    }}
                    key={indx}
                  >
                    <ListItemText primary={menu.title} />
                  </ListItem>
                  {menu.submenus && menu.submenus.length > 0 && (
                    <List component="div" className={classes.nested}>
                      {menu.submenus.map((subm, submIndx) => (
                        <ListItem
                          button
                          onClick={() => {
                            // check if window url doesn't contains link
                            if (
                              subm.link &&
                              !window.location.href.includes(subm.link)
                            ) {
                              history.push(subm.link);
                            }
                          }}
                          key={submIndx}
                        >
                          <ListItemIcon>
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary={subm.title} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Dialog>
        </>
      )}
    </>
  );
}
